/* You can add global styles to this file, and also import other style files */
\:root
  --primary-gold-color: #FF9F1C
  --primary-silver-color: #C0C0C0

.full-width
  width: 100%

.pointer
  cursor: pointer

.pointer-events-none
  pointer-events: none
