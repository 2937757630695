
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


$custom-orange-accent: (
        50: #ffd59b,
        100: #ffca82,
        200: #ffbf68,
        300: #ffb54f,
        400: #ffaa35,
        500: #ff9f1c,
        600: #ff9402,
        700: #e88600,
        800: #ce7700,
        900: #b56800,
        A100: #ffe0b5,
        A200: #ffeace,
        A400: #fff5e8,
        A700: #9b5a00,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $dark-primary-text,
                400: $dark-primary-text,
                500: $dark-primary-text,
                600: $dark-primary-text,
                700: $dark-primary-text,
                800: $dark-primary-text,
                900: $dark-primary-text,
                A100: $dark-primary-text,
                A200: $dark-primary-text,
                A400: $dark-primary-text,
                A700: $dark-primary-text,
        )
);

$custom-dark-blue-background: (
        50: #045ca3,
        100: #044e8a,
        200: #034072,
        300: #023259,
        400: #022440,
        500: #011627,
        600: #00080e,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #056abc,
        A200: #0578d5,
        A400: #0686ee,
        A700: #000000,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $dark-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $dark-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

$frontend-primary: mat-palette($custom-orange-accent);
$frontend-accent: mat-palette($custom-dark-blue-background);

// The warn palette is optional (defaults to red).
$frontend-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$frontend-theme: mat-light-theme($frontend-primary, $frontend-accent, $frontend-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($frontend-theme);


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
